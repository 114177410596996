<script lang="ts" setup>
const { t } = useT();
</script>
<template>
	<div class="favorite-empty">
		<NuxtImg
			src="/nuxt-img/card-games/favorite-empty.png"
			width="85"
			height="85"
			webp="avif"
			loading="lazy"
			alt="favorite-empty"
		/>
		<AText variant="tampa" :modifiers="['bold']" as="h3">{{ t("Favorite games") }}</AText>
		<AText variant="toledo"
			>{{ t("Mark your favorites for quick access! Click the heart icon to the left on the play button.") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.favorite-empty {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	gap: 4px;
	padding: 16px 0;

	@include media-breakpoint-down(md) {
		padding: 28px 0;
	}

	h3 {
		margin-bottom: 24px;
		color: var(--neutral-100);
	}

	span {
		max-width: 333px;
	}
}
</style>
